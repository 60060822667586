import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { LanguageService } from './language.service';
import { ECarType } from '../models/ecar-type';
import Long from "long";
import { Color } from 'ol/color';

@Injectable({
  providedIn: 'root'
})
/**
 * adding utils functions
 */
export class UtilsService {

  constructor(private languageService: LanguageService) {

    this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        this.initTurnList();
      }
    });
  }

  public chargingstationsImg: string[][] = [["plug_ccs.svg", "CCS"], ["plug_chademo.svg", "CHADEMO"], ["plug_schuko.svg", "SCHUKO"],
  ["plug_supercharger.svg", "TESLA"], ["plug_type1.svg", "TYPE 1"], ["plug_type2.svg", "TYPE 2"]];

  public defaultCarIndex: number = 69;

  public turnList: { id: string, name: string, icon: string, sound: string }[] = [];

  public laneList: string[] = ["TURN_TYPE_U_TURN_LEFT",
    "TURN_TYPE_SHARP_LEFT",
    "TURN_TYPE_LEFT",
    "TURN_TYPE_SLIGHT_LEFT",
    "TURN_TYPE_STRAIGHT",
    "TURN_TYPE_SLIGHT_RIGHT",
    "TURN_TYPE_RIGHT",
    "TURN_TYPE_SHARP_RIGHT",
    "TURN_TYPE_U_TURN_RIGHT"];

  public laneTable: number[] = [4, 3, 5, 2, 6, 1, 7, 0, 8];

  private weatherImages: { id: number, img: string }[] = [
    { id: 1, img: "weather_icon_clear_sky_day.svg" },
    { id: 2, img: "weather_icon_few_clouds_day.svg" },
    { id: 3, img: "weather_icon_scattered_clouds.svg" },
    { id: 4, img: "weather_icon_broken_clouds.svg" },
    { id: 9, img: "weather_icon_shower_rain.svg" },
    { id: 10, img: "weather_icon_rain.svg" },
    { id: 11, img: "weather_icon_thunderstorm.svg" },
    { id: 13, img: "weather_icon_snow.svg" },
    { id: 50, img: "weather_icon_mist.svg" },
    { id: 101, img: "weather_icon_clear_sky_night.svg" },
    { id: 102, img: "weather_icon_few_clouds_night.svg" },
    { id: 103, img: "weather_icon_scattered_clouds.svg" },
    { id: 104, img: "weather_icon_broken_clouds.svg" },
    { id: 109, img: "weather_icon_shower_rain.svg" },
    { id: 110, img: "weather_icon_rain.svg" },
    { id: 111, img: "weather_icon_thunderstorm.svg" },
    { id: 113, img: "weather_icon_snow.svg" },
    { id: 150, img: "weather_icon_mist.svg" }
  ];

  public getWeatherImgById(id: number): string {
    return this.weatherImages.find((el) => { return el.id == id })?.img;
  }

  public kmToMile(km: number): number {
    return km * 0.621371192;
  }

  public mToFeet(m: number): number {
    return m * 3.2808399;
  }

  public mToMiles(m: number): number {
    return m * 0.0006213712;
  }

  public mphToKmph(mph: number): number {
    return mph / 0.621371192;
  }

  public kmphToMph(kmph: number): number {
    return kmph * 0.621371192;
  }

  public mpsToKmph(mps: number): number {
    return mps * 3.6;
  }

  public KmphToMps(kmph: number): number {
    return kmph / 3.6;
  }

  public mToYard(m: number): number {
    return m * 1.0936133;
  }

  public yardToM(yard: number): number {
    return yard / 1.0936133;
  }

  public mileToM(mile: number): number {
    return mile / 0.00062137
  }

  public barToPsi(bar: number): number {
    return bar * 14.5037738;
  }

  public toRad(deg): number {
    return deg * Math.PI / 180;
  }

  public toDeg(rad): number {
    return rad * 180 / Math.PI;
  }

  public getChargeTime(chargeTimeHour, chargeTimeMin): string{
    if (chargeTimeHour > 0) {
      if (chargeTimeMin == 0) {
        return `+${chargeTimeHour}${this.languageService.languageJSON.Unit_Hour_Short}`;
      }
      else {
        return `+${chargeTimeHour}${this.languageService.languageJSON.Unit_Hour_Short}${chargeTimeMin}${this.languageService.languageJSON.Unit_Minute_Short}`;
      }
    }
    else {
      return `+${chargeTimeMin}${this.languageService.languageJSON.Unit_Minute_Short}`;
    }
  }

  initTurnList() {
    this.turnList = [
      /*  0*/{ id: "NONE", name: this.languageService.languageJSON.TurnList_NONE, icon: "", sound: "" },
      /*  1*/{ id: "ENTER_HIGHWAY", name: this.languageService.languageJSON.TurnList_ENTER_HIGHWAY, icon: "enter_highway.svg", sound: "EnterHighway.mp3" },
      /*  2*/{ id: "EXIT_HIGHWAY", name: this.languageService.languageJSON.TurnList_EXIT_HIGHWAY, icon: "exit_highway.svg", sound: "ExitHighway.mp3" },
      /*  3*/{ id: "KEEP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT, icon: "keep_left.svg", sound: "KeepLeft.mp3" },
      /*  4*/{ id: "KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT, icon: "keep_right.svg", sound: "KeepRight.mp3" },
      /*  5*/{ id: "KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT, icon: "keep_straight.svg", sound: "KeepStraight.mp3" },
      /*  6*/{ id: "TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_LEFT, icon: "turn_left.svg", sound: "TurnLeft.mp3" },
      /*  7*/{ id: "TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT, icon: "turn_right.svg", sound: "TurnRight.mp3" },
      /*  8*/{ id: "TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT, icon: "turn_slight_left.svg", sound: "TurnSlightLeft.mp3" },
      /*  9*/{ id: "TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT, icon: "turn_slight_right.svg", sound: "TurnSlightRight.mp3" },
      /* 10*/{ id: "TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT, icon: "turn_sharp_left.svg", sound: "TurnSharpLeft.mp3" },
      /* 11*/{ id: "TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT, icon: "turn_sharp_right.svg", sound: "TurnSharpRight.mp3" },
      /* 12*/{ id: "KEEP_LEFT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_KEEP_LEFT, icon: "", sound: "KeepLeftThenKeepLeft.mp3" },
      /* 13*/{ id: "KEEP_LEFT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_KEEP_RIGHT, icon: "", sound: "KeepLeftThenKeepRight.mp3" },
      /* 14*/{ id: "KEEP_LEFT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_KEEP_STRAIGHT, icon: "", sound: "KeepLeftThenKeepStraight.mp3" },
      /* 15*/{ id: "KEEP_LEFT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_TURN_LEFT, icon: "", sound: "KeepLeftThenTurnLeft.mp3" },
      /* 16*/{ id: "KEEP_LEFT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_TURN_RIGHT, icon: "", sound: "KeepLeftThenTurnRight.mp3" },
      /* 17*/{ id: "KEEP_LEFT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_TURN_SLIGHT_LEFT, icon: "", sound: "KeepLeftThenTurnSlightLeft.mp3" },
      /* 18*/{ id: "KEEP_LEFT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_TURN_SLIGHT_RIGHT, icon: "", sound: "KeepLeftThenTurnSharpLeft.mp3" },
      /* 19*/{ id: "KEEP_LEFT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_TURN_SHARP_LEFT, icon: "", sound: "KeepLeftThenTurnSharpLeft.mp3" },
      /* 20*/{ id: "KEEP_LEFT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_LEFT_AND_TURN_SHARP_RIGHT, icon: "", sound: "KeepLeftThenTurnSharpRight.mp3" },
      /* 21*/{ id: "KEEP_RIGHT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_KEEP_LEFT, icon: "", sound: "KeepRightThenKeepLeft.mp3" },
      /* 22*/{ id: "KEEP_RIGHT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_KEEP_RIGHT, icon: "", sound: "KeepRightThenKeepRight.mp3" },
      /* 23*/{ id: "KEEP_RIGHT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_KEEP_STRAIGHT, icon: "", sound: "KeepRightThenKeepStraight.mp3" },
      /* 24*/{ id: "KEEP_RIGHT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_TURN_LEFT, icon: "", sound: "KeepRightThenTurnLeft.mp3" },
      /* 25*/{ id: "KEEP_RIGHT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_TURN_RIGHT, icon: "", sound: "KeepRightThenTurnRight.mp3" },
      /* 26*/{ id: "KEEP_RIGHT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_TURN_SLIGHT_LEFT, icon: "", sound: "KeepRightThenTurnSlightLeft.mp3" },
      /* 27*/{ id: "KEEP_RIGHT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_TURN_SLIGHT_RIGHT, icon: "", sound: "KeepRightThenTurnSlightRight.mp3" },
      /* 28*/{ id: "KEEP_RIGHT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_TURN_SHARP_LEFT, icon: "", sound: "KeepRightThenTurnSharpLeft.mp3" },
      /* 29*/{ id: "KEEP_RIGHT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_RIGHT_AND_TURN_SHARP_RIGHT, icon: "", sound: "KeepRightThenTurnSharpRight.mp3" },
      /* 30*/{ id: "KEEP_STRAIGHT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_KEEP_LEFT, icon: "", sound: "KeepStraightThenKeepLeft.mp3" },
      /* 31*/{ id: "KEEP_STRAIGHT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_KEEP_RIGHT, icon: "", sound: "KeepStraightThenKeepRight.mp3" },
      /* 32*/{ id: "KEEP_STRAIGHT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_KEEP_STRAIGHT, icon: "", sound: "KeepStraightThenKeepStraight.mp3" },
      /* 33*/{ id: "KEEP_STRAIGHT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_TURN_LEFT, icon: "", sound: "KeepStraightThenTurnLeft.mp3" },
      /* 34*/{ id: "KEEP_STRAIGHT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_TURN_RIGHT, icon: "", sound: "KeepStraightThenTurnRight.mp3" },
      /* 35*/{ id: "KEEP_STRAIGHT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_TURN_SLIGHT_LEFT, icon: "", sound: "KeepStraightThenTurnSlightLeft.mp3" },
      /* 36*/{ id: "KEEP_STRAIGHT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_TURN_SLIGHT_RIGHT, icon: "", sound: "KeepStraightThenTurnSlightRight.mp3" },
      /* 37*/{ id: "KEEP_STRAIGHT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_TURN_SHARP_LEFT, icon: "", sound: "KeepStraightThenTurnSharpLeft.mp3" },
      /* 38*/{ id: "KEEP_STRAIGHT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_KEEP_STRAIGHT_AND_TURN_SHARP_RIGHT, icon: "", sound: "KeepStraightThenTurnSharpRight.mp3" },
      /* 39*/{ id: "TURN_RIGHT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_KEEP_LEFT, icon: "", sound: "TurnRightThenKeepLeft.mp3" },
      /* 40*/{ id: "TURN_RIGHT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_KEEP_RIGHT, icon: "", sound: "TurnRightThenKeepRight.mp3" },
      /* 41*/{ id: "TURN_RIGHT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_KEEP_STRAIGHT, icon: "", sound: "TurnRightThenKeepStraight.mp3" },
      /* 42*/{ id: "TURN_RIGHT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_TURN_LEFT, icon: "turn_right_and_turn_left.svg", sound: "TurnRightThenTurnLeft.mp3" },
      /* 43*/{ id: "TURN_RIGHT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_TURN_RIGHT, icon: "turn_right_and_turn_right.svg", sound: "TurnRightThenTurnRight.mp3" },
      /* 44*/{ id: "TURN_RIGHT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_TURN_SLIGHT_LEFT, icon: "turn_right_and_turn_slight_left.svg", sound: "TurnRightThenTurnSlightLeft.mp3" },
      /* 45*/{ id: "TURN_RIGHT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_TURN_SLIGHT_RIGHT, icon: "turn_right_and_turn_slight_right.svg", sound: "TurnRightThenTurnSlightRight.mp3" },
      /* 46*/{ id: "TURN_RIGHT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_TURN_SHARP_LEFT, icon: "turn_right_and_turn_sharp_left.svg", sound: "TurnRightThenTurnSharpLeft.mp3" },
      /* 47*/{ id: "TURN_RIGHT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_RIGHT_AND_TURN_SHARP_RIGHT, icon: "turn_right_and_turn_sharp_right.svg", sound: "TurnRightThenTurnSharpRight.mp3" },
      /* 48*/{ id: "TURN_LEFT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_KEEP_LEFT, icon: "", sound: "TurnLeftThenKeepLeft.mp3" },
      /* 49*/{ id: "TURN_LEFT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_KEEP_RIGHT, icon: "", sound: "TurnLeftThenKeepRight.mp3" },
      /* 50*/{ id: "TURN_LEFT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_KEEP_STRAIGHT, icon: "", sound: "TurnLeftThenKeepStraight.mp3" },
      /* 51*/{ id: "TURN_LEFT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_TURN_LEFT, icon: "turn_left_and_turn_left.svg", sound: "TurnLeftThenTurnLeft.mp3" },
      /* 52*/{ id: "TURN_LEFT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_TURN_RIGHT, icon: "turn_left_and_turn_right.svg", sound: "TurnLeftThenTurnRight.mp3" },
      /* 53*/{ id: "TURN_LEFT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_TURN_SLIGHT_LEFT, icon: "turn_left_and_turn_slight_left.svg", sound: "TurnLeftThenTurnSlightLeft.mp3" },
      /* 54*/{ id: "TURN_LEFT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_TURN_SLIGHT_RIGHT, icon: "turn_left_and_turn_slight_right.svg", sound: "TurnLeftThenTurnSlightRight.mp3" },
      /* 55*/{ id: "TURN_LEFT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_TURN_SHARP_LEFT, icon: "turn_left_and_turn_sharp_left.svg", sound: "TurnLeftThenTurnSharpLeft.mp3" },
      /* 56*/{ id: "TURN_LEFT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_LEFT_AND_TURN_SHARP_RIGHT, icon: "turn_left_and_turn_sharp_right.svg", sound: "TurnLeftThenTurnSharpRight.mp3" },
      /* 57*/{ id: "TURN_SLIGHT_LEFT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_KEEP_LEFT, icon: "", sound: "TurnSlightLeftThenKeepLeft.mp3" },
      /* 58*/{ id: "TURN_SLIGHT_LEFT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_KEEP_RIGHT, icon: "", sound: "TurnSlightLeftThenKeepRight.mp3" },
      /* 59*/{ id: "TURN_SLIGHT_LEFT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_KEEP_STRAIGHT, icon: "", sound: "TurnSlightLeftThenKeepStraight.mp3" },
      /* 60*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_TURN_LEFT, icon: "turn_slight_left_and_turn_left.svg", sound: "TurnSlightLeftThenTurnLeft.mp3" },
      /* 61*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_TURN_RIGHT, icon: "turn_slight_left_and_turn_right.svg", sound: "TurnSlightLeftThenTurnRight.mp3" },
      /* 62*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_TURN_SLIGHT_LEFT, icon: "turn_slight_left_and_turn_slight_left.svg", sound: "TurnSlightLeftThenTurnSlightLeft.mp3" },
      /* 63*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_TURN_SLIGHT_RIGHT, icon: "turn_slight_left_and_turn_slight_right.svg", sound: "TurnSlightLeftThenTurnSlightRight.mp3" },
      /* 64*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_TURN_SHARP_LEFT, icon: "turn_slight_left_and_turn_sharp_left.svg", sound: "TurnSlightLeftThenTurnSharpLeft.mp3" },
      /* 65*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_LEFT_AND_TURN_SHARP_RIGHT, icon: "turn_slight_left_and_turn_sharp_right.svg", sound: "TurnSlightLeftThenTurnSharpRight.mp3" },
      /* 66*/{ id: "TURN_SLIGHT_RIGHT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_KEEP_LEFT, icon: "", sound: "TurnSlightRightThenKeepLeft.mp3" },
      /* 67*/{ id: "TURN_SLIGHT_RIGHT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_KEEP_RIGHT, icon: "", sound: "TurnSlightRightThenKeepRight.mp3" },
      /* 68*/{ id: "TURN_SLIGHT_RIGHT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_KEEP_STRAIGHT, icon: "", sound: "TurnSlightRightThenKeepStraight.mp3" },
      /* 69*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_TURN_LEFT, icon: "turn_slight_right_and_turn_left.svg", sound: "TurnSlightRightThenTurnLeft.mp3" },
      /* 70*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_TURN_RIGHT, icon: "turn_slight_right_and_turn_right.svg", sound: "TurnSlightRightThenTurnRight.mp3" },
      /* 71*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_TURN_SLIGHT_LEFT, icon: "turn_slight_right_and_turn_slight_left.svg", sound: "TurnSlightRightThenTurnSlightLeft.mp3" },
      /* 72*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_TURN_SLIGHT_RIGHT, icon: "turn_slight_right_and_turn_slight_right.svg", sound: "TurnSlightRightThenTurnSlightRight.mp3" },
      /* 73*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_TURN_SHARP_LEFT, icon: "turn_slight_right_and_turn_sharp_left.svg", sound: "TurnSlightRightThenTurnSharpLeft.mp3" },
      /* 74*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SLIGHT_RIGHT_AND_TURN_SHARP_RIGHT, icon: "turn_slight_right_and_turn_sharp_right.svg", sound: "TurnSlightRightThenTurnSharpRight.mp3" },
      /* 75*/{ id: "TURN_SHARP_LEFT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_KEEP_LEFT, icon: "", sound: "TurnSharpLeftThenKeepLeft.mp3" },
      /* 76*/{ id: "TURN_SHARP_LEFT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_KEEP_RIGHT, icon: "", sound: "TurnSharpLeftThenKeepRight.mp3" },
      /* 77*/{ id: "TURN_SHARP_LEFT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_KEEP_STRAIGHT, icon: "", sound: "TurnSharpLeftThenKeepStraight.mp3" },
      /* 78*/{ id: "TURN_SHARP_LEFT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_TURN_LEFT, icon: "turn_sharp_left_and_turn_left.svg", sound: "TurnSharpLeftThenTurnLeft.mp3" },
      /* 79*/{ id: "TURN_SHARP_LEFT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_TURN_RIGHT, icon: "turn_sharp_left_and_turn_right.svg", sound: "TurnSharpLeftThenTurnRight.mp3" },
      /* 80*/{ id: "TURN_SHARP_LEFT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_TURN_SLIGHT_LEFT, icon: "turn_sharp_left_and_turn_slight_left.svg", sound: "TurnSharpLeftThenTurnSlightLeft.mp3" },
      /* 81*/{ id: "TURN_SHARP_LEFT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_TURN_SLIGHT_RIGHT, icon: "turn_sharp_left_and_turn_slight_right.svg", sound: "TurnSharpLeftThenTurnSlightRight.mp3" },
      /* 82*/{ id: "TURN_SHARP_LEFT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_TURN_SHARP_LEFT, icon: "turn_sharp_left_and_turn_sharp_left.svg", sound: "TurnSharpLeftThenTurnSharpLeft.mp3" },
      /* 83*/{ id: "TURN_SHARP_LEFT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_LEFT_AND_TURN_SHARP_RIGHT, icon: "turn_sharp_left_and_turn_sharp_right.svg", sound: "TurnSharpLeftThenTurnSharpRight.mp3" },
      /* 84*/{ id: "TURN_SHARP_RIGHT_AND_KEEP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_KEEP_LEFT, icon: "", sound: "TurnSharpRightThenKeepLeft.mp3" },
      /* 85*/{ id: "TURN_SHARP_RIGHT_AND_KEEP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_KEEP_RIGHT, icon: "", sound: "TurnSharpRightThenKeepRight.mp3" },
      /* 86*/{ id: "TURN_SHARP_RIGHT_AND_KEEP_STRAIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_KEEP_STRAIGHT, icon: "", sound: "TurnSharpRightThenKeepStraight.mp3" },
      /* 87*/{ id: "TURN_SHARP_RIGHT_AND_TURN_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_TURN_LEFT, icon: "turn_sharp_right_and_turn_left.svg", sound: "TurnSharpRightThenTurnLeft.mp3" },
      /* 88*/{ id: "TURN_SHARP_RIGHT_AND_TURN_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_TURN_RIGHT, icon: "turn_sharp_right_and_turn_right.svg", sound: "TurnSharpRightThenTurnRight.mp3" },
      /* 89*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SLIGHT_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_TURN_SLIGHT_LEFT, icon: "turn_sharp_right_and_turn_slight_left.svg", sound: "TurnSharpRightThenTurnSlightLeft.mp3" },
      /* 90*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SLIGHT_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_TURN_SLIGHT_RIGHT, icon: "turn_sharp_right_and_turn_slight_right.svg", sound: "TurnSharpRightThenTurnSlightRight.mp3" },
      /* 91*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SHARP_LEFT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_TURN_SHARP_LEFT, icon: "turn_sharp_right_and_turn_sharp_left.svg", sound: "TurnSharpRightThenTurnSharpLeft.mp3" },
      /* 92*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SHARP_RIGHT", name: this.languageService.languageJSON.TurnList_TURN_SHARP_RIGHT_AND_TURN_SHARP_RIGHT, icon: "turn_sharp_right_and_turn_sharp_right.svg", sound: "TurnSharpRightThenTurnSharpRight.mp3" },
      /* 93*/{ id: "U_TURN", name: this.languageService.languageJSON.TurnList_U_TURN, icon: "u_turn.svg", sound: "UTurn.mp3" },
      /* 94*/{ id: "ENTER_ROUNDABOUT", name: this.languageService.languageJSON.TurnList_ENTER_ROUNDABOUT, icon: "enter_roundabout.svg", sound: "EnterRoundabout.mp3" },
      /* 95*/{ id: "EXIT_ROUNDABOUT", name: this.languageService.languageJSON.TurnList_EXIT_ROUNDABOUT, icon: "exit_roundabout.svg", sound: "ExitRoundabout.mp3" },
      /* 96*/{ id: "TAKE_FERRY", name: this.languageService.languageJSON.TurnList_TAKE_FERRY, icon: "take_ferry.svg", sound: "TakeFerry.mp3" },
      /* 97*/{ id: "EXIT_FERRY", name: this.languageService.languageJSON.TurnList_EXIT_FERRY, icon: "exit_ferry.svg", sound: "ExitFerry.mp3" },
      /* 98*/{ id: "ARRIVE", name: this.languageService.languageJSON.TurnList_ARRIVE, icon: "arrive.svg", sound: "ArriveAtDestination.mp3" },
      /* 99*/{ id: "FOLLOW_THE_ROUTE", name: this.languageService.languageJSON.TurnList_FOLLOW_THE_ROUTE, icon: "", sound: "FollowRoute.mp3" },
      /*100*/{ id: "CHARGE", name: this.languageService.languageJSON.TurnList_CHARGE, icon: "charge.svg", sound: "Charge.mp3" },
      /*101*/{ id: "REPLAN", name: this.languageService.languageJSON.TurnList_REPLAN, icon: "", sound: "Replan.mp3" },
      /*102*/{ id: "CONDITION_CHANGED_REPLAN", name: this.languageService.languageJSON.TurnList_CONDITION_CHANGED_REPLAN, icon: "", sound: "ConditionChangedReplan.mp3" },
      /*103*/{ id: "ARRIVE_TO_WAYPOINT", name: this.languageService.languageJSON.TurnList_ARRIVE_TO_WAYPOINT, icon: "waypoint.svg", sound: "Waypoint.mp3" },
      /*104*/{ id: "TAKE_SHUTTLE_TRAIN", name: this.languageService.languageJSON.TurnList_TAKE_SHUTTLE_TRAIN, icon: "take_shuttle_train.svg", sound: "TakeShuttleTrain.mp3" },
      /*105*/{ id: "EXIT_SHUTTLE_TRAIN", name: this.languageService.languageJSON.TurnList_EXIT_SHUTTLE_TRAIN, icon: "exit_shuttle_train.svg", sound: "ExitShuttleTrain.mp3" },
      /*106*/{ id: "ARRIVE_TO_CHARGEWAYPOINT", name: this.languageService.languageJSON.TurnList_CHARGE, icon: "charge.svg", sound: "Charge.mp3" },
    ];
  }

  public isChargeId(id: string):boolean{
    if (id == "CHARGE" || id == "ARRIVE_TO_CHARGEWAYPOINT"){
      return true;
    }
    return false;
  }

  // celsius fahrenheit conversion
  public ConvertCelsiusToFahrenheit(c: number): number {
    return Math.round(((9.0 / 5.0) * c) + 32);
  }

  public validateEmail(email): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public VqrzrxFGyHNUVIRU(polyParams) {
    let SXihtOZnuC = new Md5();
    let cleanStr = this.cleanString(JSON.stringify(polyParams));
    SXihtOZnuC.appendStr(cleanStr + "OLiFNGTFc5T92WNGSRgBnpcG4crUlTg7tFII0YoNZiDPJSLcnaI5sSwU4jMw5V21");
    return SXihtOZnuC.end();
  }

  public cleanString(input: string): string {
    let output = "";
    for (let i = 0; i < input.length; i++) {
      if (input.charCodeAt(i) <= 127) {
        output += input.charAt(i);
      }
    }
    return output;
  }

  public getOCMId(charginstationId: string): string {
    const val1 = Long.fromString(charginstationId.toString());
    const val1High = val1.getHighBitsUnsigned();
    const val1Low = val1.getLowBitsUnsigned();

    const val2 = Long.fromString('9007199254740991');
    const val2High = val2.getHighBitsUnsigned();
    const val2Low = val2.getLowBitsUnsigned();

    const bitwiseAndResult = Long.fromBits(val1Low & val2Low, val1High & val2High, true);
    return bitwiseAndResult.low.toString();
  }

  public timeConverter(num: number): any {
    if (isNaN(num)) {
      return num;
    }

    const roundedNum = Math.round(num);
    let hours: any = Math.floor(roundedNum / 60);
    let minutes: any = roundedNum % 60;

    if (hours.toString().length < 2) {
      hours = "0" + hours.toString();
    }
    if (minutes.toString().length < 2) {
      minutes = "0" + minutes.toString();
    }
    return hours + ":" + minutes;
  }

  public blobToUint8Array(b) {
    let uri = URL.createObjectURL(b),
      xhr = new XMLHttpRequest(),
      i,
      ui8;

    xhr.open('GET', uri, false);
    xhr.send();

    URL.revokeObjectURL(uri);

    ui8 = new Uint8Array(xhr.response.length);

    for (i = 0; i < xhr.response.length; ++i) {
      ui8[i] = xhr.response.charCodeAt(i);
    }

    return ui8;
  }

  public angleFromCoordinate(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const dLon = this.toRad(lon2 - lon1);
    const y = Math.sin(dLon) * Math.cos(this.toRad(lat2));
    const x = Math.cos(this.toRad(lat1)) * Math.sin(this.toRad(lat2)) - Math.sin(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) * Math.cos(dLon);
    const brng = this.toDeg(Math.atan2(y, x));
    return ((brng + 360) % 360);
  }

  public distanceBetweenCoordinates(origin: number[], destination: number[]): number {
    // return distance in meters
    const lon1 = this.toRad(origin[1]),
      lat1 = this.toRad(origin[0]),
      lon2 = this.toRad(destination[1]),
      lat2 = this.toRad(destination[0]);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6366707;
    return c * EARTH_RADIUS;
  }

  public distanceBetweenCoordinatesLonLat(origin: [number, number], destination: [number, number]): number {
    // return distance in meters
    const lon1 = this.toRad(origin[0]),
      lat1 = this.toRad(origin[1]),
      lon2 = this.toRad(destination[0]),
      lat2 = this.toRad(destination[1]);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6366707;
    return c * EARTH_RADIUS;
  }

  public getRandomBetweenCoordinates(min: number, max: number): number {
    return parseFloat((Math.random() * (max - min) + min).toFixed(6));
  }

  public toFixed(num: number, precision: number): string {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  }

  // close geometry
  public closeGeometry(geometry: [number, number][][]): [number, number][][] {
    geometry.forEach(element => {
      if (element[0][0] != element[element.length - 1][0] || element[0][1] != element[element.length - 1][1]) {
        element.push(element[0]);
      }
    });

    return geometry;
  }

  public getColorStringForJamFactor(jamFactor: number): string {
    if (jamFactor > 85) {
      return 'rgb(128, 0, 0)';
    } else if (jamFactor > 50) {
      return 'rgb(255, 140, 0)';
    } else if (jamFactor > 20) {
      return 'rgb(255, 140, 0)';
    } else {
      return 'rgb(4, 156, 219)';
    }
  }

  public getColorForJamFactor(jamFactor: number): Color{
    if (jamFactor > 85) {
      return [128, 0, 0, 1];
    } else if (jamFactor > 50) {
      return [255, 140, 0, 1];
    } else if (jamFactor > 20) {
      return [255, 140, 0, 1];
    } else {
      return [4, 156, 219, 1];
    }
  }

  // rgb to hex color conversion
  public rgbToHex(r: number, g: number, b: number): string {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  public rectContains(cursorX: number, cursorY: number, rectX: number, rectY: number, rectWidth: number, rectHeight: number): boolean {
    return rectX - (rectWidth / 2) <= cursorX && cursorX <= rectX + (rectWidth / 2) &&
      rectY - (rectHeight / 2) <= cursorY && cursorY <= rectY + (rectHeight / 2);
  }

  public rotate(cx: number, cy: number, x: number, y: number, angle: number): [number, number] {
    const radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
  }

  public RotatePoint(x: number, y: number, angle: number): { x: number, y: number } {
    const sinValue = Math.sin(angle);
    const cosValue = Math.cos(angle);
    return { x: x * cosValue - y * sinValue, y: x * sinValue + y * cosValue };
  }

  public normalizeAngle(angle: number): number {
    angle = angle % 360;
    if (angle < 0) angle += 360;
    return angle;
  }

  public normalizeAngleInRad(angle: number): number {
    angle = angle % (2 * Math.PI);
    if (angle < 0) angle += 2 * Math.PI;
    return angle;
  }

  public collectCarTypes(ecars: ECarType[]): { name: string, subtypes: string[] }[] {
    const types = [];
    ecars.map((ecar: ECarType) => {
      if (!types.find((type) => { return type.name === ecar.Type })) {
        types.push({ name: ecar.Type, subtypes: [] });
      }

      if (ecar.Subtype != null) {
        types.map((type) => {
          if (type.name === ecar.Type && !type.subtypes.includes(ecar.Subtype)) {
            type.subtypes.push(ecar.Subtype);
          }
        });
      }
    });

    /** sort types */
    types.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    /** sort subtypes */
    types.map((type) => {
      /** tesla should stay s 3 x y */
      if (type.name.toLowerCase() != "tesla") {
        type.subtypes.sort();
      }
    });

    return types;
  }

  // getting the actual lod level
  public getLodLevel(map: any): number {
    let lodCurrent;
    if (map.getZoom() <= 4) {
      lodCurrent = 0;
    }
    else if (map.getZoom() <= 6) {
      lodCurrent = 1;
    }
    else if (map.getZoom() <= 8) {
      lodCurrent = 2;
    }
    else if (map.getZoom() <= 10) {
      lodCurrent = 3;
    }
    else if (map.getZoom() <= 12) {
      lodCurrent = 4;
    }
    else if (map.getZoom() <= 14) {
      lodCurrent = 5;
    }
    else if (map.getZoom() <= 16) {
      lodCurrent = 6;
    }
    else if (map.getZoom() <= 22) {
      lodCurrent = 7;
    }
    return lodCurrent;
  }

  public Lerpd(p1: number, p2: number, alpha: number): number {
    return p1 * (1 - alpha) + p2 * alpha;
  }

  public CalculateDistanceBetweenRoutePoints(planPoints: any[]): any[] {
    planPoints[0].push(0);
    for (let i = 1; i < planPoints.length; i++) {
      planPoints[i].push(this.distanceBetweenCoordinates([planPoints[i - 1][0], planPoints[i - 1][1]], [planPoints[i][0], planPoints[i][1]]));
    }
    return planPoints;
  }

  //Convert latitude and longitude to tile indices based on given resolution
  public LatLonToTile(lat, lon, res_x, res_y) {
    const tile_x = Math.floor((lon + 180) / 360 * res_x);
    const tile_y = Math.floor((90 - lat) / 180 * res_y);
    return [tile_x, tile_y];
  }

  public CalculateTimeBetweenRoutePoints(planPoints: any[], routeSegments: any[], chargePlan: any[]): any[] {
    planPoints[0].push(0);
    for (let i = 1; i < planPoints.length; i++) {
      const actDist = planPoints[i][7];
      const jamFactor = planPoints[i][6];
      let actSpeed = 0;

      if (planPoints[i][4] != 0) {
        actSpeed = planPoints[i][4];
      }
      else {
        actSpeed = planPoints[i][3];
      }

      if (jamFactor > 0) //TODO calculate with confidential factor?
      {
        if (actSpeed > 30) {
          if (actSpeed < 80) {
            actSpeed = actSpeed * 0.6;
          }
          else {
            actSpeed = actSpeed * 0.95;
          }
        }
      }

      planPoints[i].push(actDist / 1000 / actSpeed * 60);
    }
    //add charging station
    if (routeSegments && routeSegments.length > 1) {
      for (let i = 1; i < routeSegments.length; i++) {
        planPoints[routeSegments[i][1]][8] += chargePlan[i - 1][2];
      }
    }
    return planPoints;
  }

  public validURL(str: string): boolean {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  public isProductionUrl(): boolean {
    if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
      return false;
    }
    else {
      return true;
    }
  }
}
