<div class="search">
  <div class="leaflet-search" #leafletSearch [ngClass]="{'failedPlan': failedPlan}">
    <div class="route-icon" [ngClass]="{'route-charge-icon': csid}" *ngIf="routeIcon && routeIcon!='waypoint' && routeIcon!='empty'">
      <img [src]="routeIcon" alt="route" />
      <div *ngIf="waypointIdx" class="waypoint-charge-here-number">{{ waypointIdx }}</div>
    </div>
    <div class="route-icon" *ngIf="routeIcon && routeIcon=='waypoint'">
      <div class="waypoint-circle"> {{ waypointIdx }} </div>
      <div *ngIf="batteryReservedWarning" class="battery-reserved-warning-icon">!</div>
    </div>
    <div class="route-icon" *ngIf="routeIcon && routeIcon=='empty'">
      <div class="waypoint-circle"></div>
    </div>
    <input autocomplete="off" type="text" #searchInput  [placeholder]="languageService.languageJSON.InfoSidebar_SearchOnMap" [(ngModel)]="inputValue"
      [ngClass]="{'search-with-route': routeIcon}" (keyup.enter)="onSearchSubmit(searchInput.value)"
      (keyup)="searchInputChanged(searchInput.value)" (click)="searchActivated()">
      <div class="distance-text" *ngIf="distance" [hidden]="settingsService.getUnit().Distance!='metric'">
         {{ distance | turnValueIntegerThousands }} KM</div>
      <div class="distance-text" *ngIf="distance" [hidden]="settingsService.getUnit().Distance!='imperial'">
         {{ (distance * 6.21371192)/10 | turnValueIntegerThousands }} mi
      </div>
    <div class="search-image" (click)="onSearchSubmit(searchInput.value)" *ngIf="!routeIcon && !addressSearch"></div>
  </div>
  <div class="search-dropdown" *ngIf="searchActive" [ngStyle]="{'margin-top': (38-multiwayScrollFromTop)+'px'}">
    <div class="search-dropdown-item" *ngFor="let searchEl of searchResults" (click)="outputValue(searchEl)">
      {{ searchEl.description }}</div>
    <div class="google-attribution">
      <img src="assets/icons/default/ui/powered_by_google_on_non_white.png" alt="google" />
    </div>
  </div>
  <div id="attributions"></div>
</div>