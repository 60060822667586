import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { ErrorHandler } from '@angular/core'

import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';

import { InputParamsService } from './services/input-params.service';
import { MobileResolutionService } from './services/mobile-resolution.service';
import { MapService } from './services/map.service';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RhaSliderComponent } from './components/ui-panels/slider-sidebar/rha-slider/rha-slider.component';
import { MapComponent } from './components/map/map.component';
import { SliderSidebarComponent } from './components/ui-panels/slider-sidebar/slider-sidebar.component';
import { InfoSidebarComponent } from './components/ui-panels/info-sidebar/info-sidebar.component';
import { SearchComponent } from './components/search/search.component';
import { PrivacyPolicyDialogComponent } from './components/modals/privacy-policy-dialog/privacy-policy-dialog.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationHudComponent } from './components/navigation/navigation-hud/navigation-hud.component';
import { NavigationDisclaimerComponent } from './components/modals/navigation-disclaimer/navigation-disclaimer.component';
import { GpsErrorDialogComponent } from './components/modals/gps-error-dialog/gps-error-dialog.component';
import { NavigationRoadlanesComponent } from './components/navigation/navigation-roadlanes/navigation-roadlanes.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AccountDialogComponent } from './components/modals/account-dialog/account-dialog.component';
import { ManageCarsComponent } from './components/ui-panels/slider-sidebar/manage-cars/manage-cars.component';
import { CarSelectorDialogComponent } from './components/modals/car-selector-dialog/car-selector-dialog.component';
import { WindTabComponent } from './components/wind-tab/wind-tab.component';
import { TabbedInfoDialogComponent } from './components/modals/tabbed-info-dialog/tabbed-info-dialog.component';
import { PrivacyPolicyComponent } from './components/modals/tabbed-info-dialog/privacy-policy/privacy-policy.component';
import { EulaComponent } from './components/modals/tabbed-info-dialog/eula/eula.component';
import { FeedbackComponent } from './components/modals/tabbed-info-dialog/feedback/feedback.component';
import { WelcomeComponent } from './components/modals/tabbed-info-dialog/welcome/welcome.component';
import { TutorialComponent } from './components/modals/tabbed-info-dialog/tutorial/tutorial.component';
import { SettingsDialogComponent } from './components/modals/settings-dialog/settings-dialog.component';
import { SwitchComponent } from './components/modals/settings-dialog/switch/switch.component';
import { WelcomeDialogComponent } from './components/modals/welcome-dialog/welcome-dialog.component';
import { NavigationMenuComponent } from './components/navigation/navigation-menu/navigation-menu.component';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { BatteryComponent } from './components/navigation/navigation-hud/battery/battery.component';
import { VoiceInputComponent } from './components/voice-input/voice-input.component';
import { VoiceCommandConfirmationDialogComponent } from './components/modals/voice-command-confirmation-dialog/voice-command-confirmation-dialog.component';
import { MobileMainMenuComponent } from './components/mobile-main-menu/mobile-main-menu.component';
import { AddressComponent } from './components/ui-panels/address-panel/address-panel.component';
import { VoiceCommandListenDialogComponent } from './components/modals/voice-command-listen-dialog/voice-command-listen-dialog.component';
import { VoiceCommandErrorDialogComponent } from './components/modals/voice-command-error-dialog/voice-command-error-dialog.component';
import { MainComponent } from './components/main/main.component';
import { ChargerPanelComponent } from './components/ui-panels/charger-panel/charger-panel.component';
import { WebviewService } from './services/webview.service';
import { InfoDialogComponent } from './components/modals/info-dialog/info-dialog.component';
import { SubscriptionsDialogComponent } from './components/modals/subscriptions-dialog/subscriptions-dialog.component';
import { HistoryDialogComponent } from './components/modals/history-dialog/history-dialog.component';
import { HistoryPanelComponent } from './components/ui-panels/history-panel/history-panel.component';
import { UserCarsSelectorComponent } from './components/modals/subscriptions-dialog/user-cars-selector/user-cars-selector.component';
import { AccountResolverService } from './resolvers/account-resolver.service';
import { LanguageSelectorComponent } from './components/modals/welcome-dialog/language-selector/language-selector.component';
import { OperatorSearchComponent } from './components/modals/settings-dialog/operator-search/operator-search.component';
import { ValueIntegerPipe } from './pipes/value-integer.pipe';
import { ValueDecimalPipe } from './pipes/value-decimal.pipe';
import { TurnValueIntegerPipe } from './pipes/turn-value-integer.pipe';
import { TurnValueIntegerThousandsPipe } from './pipes/turn-value-integer-thousands.pipe';
import { CarFilterPipe } from './pipes/car-filter.pipe';
import { CarNamePipe } from './pipes/car-name.pipe';
import { SearchPropertyPipe } from './pipes/search-property.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { initResolver } from './resolvers/init.resolver';
import { NavigationSetBatteryDialogComponent } from './components/modals/navigation-set-battery-dialog/navigation-set-battery-dialog.component';
import { NavigationRestartDialogComponent } from './components/modals/navigation-restart-dialog/navigation-restart-dialog.component';
import { SubscriptionSuccessDialogComponent } from './components/modals/subscriptions-dialog/subscription-success-dialog/subscription-success-dialog.component';
import { SubscriptionDeleteDialogComponent } from './components/modals/subscriptions-dialog/subscription-delete-dialog/subscription-delete-dialog.component';
import { SubscriptionRequiredDialogComponent } from './components/modals/subscriptions-dialog/subscription-required-dialog/subscription-required-dialog.component';
import { VoucherDialogComponent } from './components/modals/voucher-dialog/voucher-dialog.component';

const mainResolver = {
  
  webviewService: WebviewService,
  inputParamsService: InputParamsService,
  initResolver: initResolver,
  accountResolverService: AccountResolverService
};
const routes: Routes = [
  { path: '', component: MainComponent, resolve: mainResolver},
  { path: 'compare', component: MainComponent, resolve: mainResolver },
  { path: 'privacy-policy', component: MainComponent, resolve: mainResolver},
  { path: 'demo', component: MainComponent, resolve: mainResolver },
  { path: 'polyline', component: MainComponent, resolve: mainResolver },
  { path: 'verify-email', component: MainComponent, resolve: mainResolver },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

const globalSettings: RecaptchaSettings = { siteKey: "6LePecYUAAAAALxWbq0rs8n5cE_VLgHz5TChWoyv", theme: "dark" };

Bugsnag.start({
  apiKey: '81dff23ac6fa349382f1a4941443571d'
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule, MatDialogModule, MatGridListModule, MatProgressSpinnerModule, MatButtonModule, ReactiveFormsModule, MatCheckboxModule, MatSelectModule,
    MatTooltipModule, MatIconModule, MatSlideToggleModule, MatSnackBarModule,
    RouterModule.forRoot(routes, { }),
    NgScrollbarModule,
    DragDropModule,
    RecaptchaModule,
    NgPipesModule
  ],

  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    InputParamsService,
    MobileResolutionService,
    MapService,
    CookieService
  ],
  exports: [
    RouterModule
  ],
  declarations: [AppComponent,
    RhaSliderComponent, MapComponent, SliderSidebarComponent,
    InfoSidebarComponent, ValueDecimalPipe,
    ValueIntegerPipe, TurnValueIntegerPipe, TurnValueIntegerThousandsPipe, CarFilterPipe,
    CarNamePipe, SearchPropertyPipe, SearchComponent,
    PrivacyPolicyDialogComponent, NavigationComponent, NavigationHudComponent,
    NavigationDisclaimerComponent, GpsErrorDialogComponent, NavigationRoadlanesComponent,
    ProfileComponent, AccountDialogComponent, ManageCarsComponent, CarSelectorDialogComponent,
    WindTabComponent, TabbedInfoDialogComponent, PrivacyPolicyComponent, EulaComponent,
    FeedbackComponent, WelcomeComponent, TutorialComponent, SettingsDialogComponent,
    SwitchComponent, WelcomeDialogComponent, NavigationMenuComponent,
    ConfirmationDialogComponent, BatteryComponent, VoiceInputComponent, VoiceCommandConfirmationDialogComponent,
    MobileMainMenuComponent, AddressComponent, VoiceCommandListenDialogComponent, VoiceCommandErrorDialogComponent,
    MainComponent, ChargerPanelComponent, InfoDialogComponent, SubscriptionsDialogComponent, HistoryDialogComponent,
    HistoryPanelComponent, UserCarsSelectorComponent, LanguageSelectorComponent,
    OperatorSearchComponent, NavigationSetBatteryDialogComponent, NavigationRestartDialogComponent,
    SubscriptionSuccessDialogComponent, SubscriptionDeleteDialogComponent,
    SubscriptionRequiredDialogComponent, VoucherDialogComponent],
  bootstrap: [AppComponent]
})

export class AppModule {
  // preventing iphone pinch zoom
  constructor() {

    //if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)) {
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
    document.addEventListener('gesturechange', function (e) {
      e.preventDefault();
    });
    document.addEventListener('gestureend', function (e) {
      e.preventDefault();
    });
    //}
    window.oncontextmenu = function (event) {
      const target: any = event.target;
      if (target.nodeName && target.nodeName != "INPUT") {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }

      return null;
    };
  }
}
