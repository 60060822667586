<div class="subscription-dialog">

    <h1 mat-dialog-title>
        Packages
    </h1>
    <div mat-dialog-content>
        <div class="logo">
            <img src="assets/icons/dark/logo/ev_navigation_logo.png"/>
            <h2>PRO &<img id="proplus-icon" src="assets/icons/default/ui/pro_logo.svg"/></h2>
        </div>
        <div class="content">
            <p>{{ feature }} is part of the PRO and PRO<small>+</small> package.</p>
            <p *ngIf="!accountService.getIsAuthorized()">Create a user and activate PRO and PRO<small>+</small> package.</p>
            <p *ngIf="accountService.getIsAuthorized()">Activate PRO and PRO<small>+</small> package.</p>

            <p *ngIf="!accountService.getIsAuthorized()"><a (click)="login()">I aleady have an account</a></p>
        </div>
        
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="register()" *ngIf="!accountService.getIsAuthorized()">{{ languageService.languageJSON.AccountDialog_Register }}</button>
        <button mat-button (click)="activate()" *ngIf="accountService.getIsAuthorized()">Activate</button>
        <button mat-button (click)="close()">{{ languageService.languageJSON.Global_Cancel }}</button>
    </div>

</div>