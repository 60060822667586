import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ECar } from 'src/app/models/ecar';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';

/**
   * car selector for the user cars
   */
@Component({
  selector: 'app-user-cars-selector',
  templateUrl: './user-cars-selector.component.html',
  styleUrls: ['./user-cars-selector.component.scss']
})
export class UserCarsSelectorComponent implements OnInit {

  @Input() newSubscription: boolean;
  @Input() historySelector: boolean;
  @Output() valueChange = new EventEmitter();
  public userECars: ECar[];
  public selectedECar: ECar;

  constructor(public accountService: AccountService, private consoleLoggerService: ConsoleLoggerService) { }

  ngOnInit(): void {
    let userCarsArray = this.accountService.getUserProfile().Ecars;
    this.userECars = userCarsArray;

    if (this.userECars.length > 0) {
      this.selectedECar = this.userECars[0];
    }

    // set active car as selected
    if (this.historySelector) {
      this.selectedECar = this.accountService.user.getSelectedUserCar();

      const lastSelectedVehicle = this.accountService.getHistoryLastSelectedVehicle();
      if (lastSelectedVehicle) {
        this.selectedECar = lastSelectedVehicle;
        this.accountService.setHistoryLastSelectedVehicle(null);
      }
    }

    this.valueChange.emit(this.selectedECar);
  }

  public selectionChanged(): void {
    this.valueChange.emit(this.selectedECar);
  }
}
