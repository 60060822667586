<div class="info-sidebar"
  [ngClass]="{'info': mobileVisiblePanel=='info', 'minimalised': selectedMode != 'route', 'searched': searched,
  'mobile-fullscreen': mobileVisiblePanel=='search', 'navigation-active': navigation, 'demo': demo, 'account': isAuthorized}"
  [ngStyle]="{'height': selectedMode === 'rha' ? 'initial' : infoSidebarHeight + 'px'}">

  <h1 mat-dialog-title *ngIf="mobileVisiblePanel=='search'">
    <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/back.svg'" class="back-button"
      (click)="mobileResolutionService.setMobileVisiblePanel('')" />
    <span class="panel-header">{{ languageService.languageJSON.InfoSidebar_SearchOnMap }}</span>
  </h1>

  <div class="flex-row" *ngIf="!navigation">
    <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/back.svg'" class="back-button back-button-search"
      (click)="searched=false" *ngIf="searched" />

    <app-search *ngIf="selectedMode != 'route'" (searchChanged)="searchChangedHandler($event)"
      [inputValue]="rhaSearchValue" id="rha-search" [failedPlan]="false">
    </app-search>
    <div class="search-element-functions addresses" *ngIf="selectedMode != 'route'">
      <div class="search-element-functions-icon" (click)="setSearchedToHome()">
        <img [src]="'assets/icons/dark/ui/home_icon.svg'" alt="home" />
      </div>
      <div class="search-element-functions-icon" (click)="setSearchedToWork()"><img
          [src]="'assets/icons/dark/ui/work_icon.svg'" alt="home" />
      </div>
    </div>
    <div class="search-element-functions searched-functions">
      <div class="search-element-functions-icon start-icon" (click)="setStartCoordsParamToSearchedResult()">
        <img src="assets/icons/dark/ui/start_icon.svg" alt="set to start point"
          [matTooltip]="languageService.languageJSON.InfoSidebar_Tooltip_SetToStart" matTooltipShowDelay="500"
          matTooltipClass="google-tooltip" />
      </div>
      <div class="search-element-functions-icon target-icon" (click)="setEndCoordsParamToSearchedResult()"><img
          src="assets/icons/dark/ui/target_icon.svg" alt="set to destination"
          [matTooltip]="languageService.languageJSON.InfoSidebar_Tooltip_SetToDest" matTooltipShowDelay="500"
          matTooltipClass="google-tooltip" />
      </div>
    </div>
  </div>

  <div class="loader" [hidden]="routeData!=undefined || selectedMode != 'route' || failedPlan || noRoute">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="route-planned" [hidden]="(!routeData && !failedPlan && !noRoute)">
    <app-navigation-hud *ngIf="navigation" [Desktop]="true"></app-navigation-hud>
    <app-navigation-roadlanes [Desktop]="true" *ngIf="navigation && roadLanesLength>1"></app-navigation-roadlanes>

    <!--<label for="gpxfile" *ngIf="replayMode" [hidden]="selectedMode != 'route'" class="navi-btn test-btn"
      [ngClass]="{ 'grayscale': failedPlan || noRoute }" name="gpx_btn">
      <h4 class="navi-btn-text">GPX</h4>
    </label>
    <input *ngIf="replayMode" [hidden]="mobileResolution" style="display: none;" type="file" id="gpxfile"
      (change)="gpxNavigation($event)" />-->

    <ng-scrollbar #infoScrollbar class="info-scrollbar" [ngClass]="{'scrollbar-extended': lastHudComputers==1 }"
      [hidden]="mobileResolution && mobileVisibleRoutePanel!='routepoints' || selectedMode!='route'"
      [autoHeightDisabled]="false" [autoWidthDisabled]="true">
      <div class="scroll-container scroll-active-width">

        <div cdkDropList (cdkDropListDropped)="drop($event)" [hidden]="navigation">
          <div class="search-el" *ngFor="let routeEl of routeList; let i = index" cdkDrag>
            <div class="dnd-icon grab" [ngClass]="{'inactive': !isDragAndDrop()}" cdkDragHandle><img
                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/draganddroptrack_icon.svg'"
                alt="drag and drop" />
            </div>
            <app-search *ngIf="i==0" (searchChanged)="waypointChangedHandler($event, i)"
              (keyPressed)="keyPressedHandler()" [multiwayScrollFromTop]="multiwayScrollFromTop"
              [inputValue]="routeEl.display_name"
              [routeIcon]="getRouteStartIcon(routeList[i])"
              id="route-search-start" [failedPlan]="(failedPlan && maxReachedWaypoint<i)">
            </app-search>
            <app-search *ngIf="i!=0 && i!=routeList.length-1" (searchChanged)="waypointChangedHandler($event, i)"
              (keyPressed)="keyPressedHandler()" [multiwayScrollFromTop]="multiwayScrollFromTop"
              [inputValue]="routeEl.display_name" [routeIcon]="getRouteWaypointIcon(routeList[i])"
              [waypointIdx]="i" id="route-search-start" [failedPlan]="(failedPlan && maxReachedWaypoint<i)"
              [distance]="routeList[i].distance" [csid]="routeList[i].csid">
            </app-search>
            <app-search *ngIf="i==routeList.length-1 && routeList.length!=1"
              (searchChanged)="waypointChangedHandler($event, i)" (keyPressed)="keyPressedHandler()"
              [inputValue]="routeEl.display_name" [multiwayScrollFromTop]="multiwayScrollFromTop"
              [routeIcon]="getRouteEndpointIcon(routeList[i])"
              id="route-search-end" [failedPlan]="(failedPlan && maxReachedWaypoint<i)"
              [distance]="routeList[i].distance" [csid]="routeList[i].csid">
            </app-search>
            <div class="dnd-icon" (click)="deletePoint(i)" *ngIf="i!=0"
              [matTooltip]="languageService.languageJSON.InfoSidebar_Tooltip_RemoveRoutePoint" matTooltipShowDelay="500"
              matTooltipClass="google-tooltip">
              <img class="delete" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/deletetrack_icon.svg'"
                alt="delete route point" />
            </div>
            <div class="dnd-icon" (click)="setStartCoordsToYourGeolocation()" *ngIf="i==0"
              [matTooltip]="languageService.languageJSON.InfoSidebar_Tooltip_SetToYourLocation"
              matTooltipShowDelay="500" matTooltipClass="google-tooltip">
              <img class="locate-icon" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/locate_icon.svg'"
                alt="set to your location" />
            </div>
            <div class="dragged-placeholder" *cdkDragPlaceholder></div>
          </div>
        </div>

        <div *ngIf="routeList.length>=5 && !navigation" class="silent-warning">{{
          languageService.languageJSON.InfoSidebar_WaypointLimitReached }}</div>

        <div id="add-waypoint-btn" class="waypoints-menu"
          *ngIf="!navigation && selectedMode=='route' && routeList.length<5"
          [hidden]="mobileResolution && mobileVisibleRoutePanel!='routepoints'">
          <div class="navi-icon-outer">
            <div class="search-element-functions-icon" (click)="addRoutePoint()"
              [ngClass]="{'grayscale': emptySearchInput || failedPlan}">
              <img src="assets/icons/dark/ui/addtrackpoint_icon.svg" />
            </div>

            <div class="search-element-functions-icon" (click)="addHomeToRoutePoint()">
              <img [src]="'assets/icons/dark/ui/home_icon.svg'" alt="home" />
            </div>
            <div class="search-element-functions-icon" (click)="addWorkToRoutePoint()"><img
                [src]="'assets/icons/dark/ui/work_icon.svg'" alt="home" />
            </div>
          </div>
        </div>
      </div>

      <div class="info-sidebar-scrollbar-wrapper scroll-active-width" [hidden]="failedPlan"
        [ngClass]="{'infopanel': mobileVisibleRoutePanel=='info' }">

        <!-- Track information -->
        <div class="planned-data" *ngIf="routeData">
          <div class="flex-outer">
            <div class="flex-column">
              <div class="data-element">
                <div class="data-name">
                  {{ languageService.languageJSON.Global_Distance }}
                </div>
                <div class="data-value"
                  [hidden]="!showMetricKMeter(routeData.RouteInfo.Distance) || failedPlan || noRoute || loadingMap">
                  {{ routeData.RouteInfo.Distance /1000 | turnValueIntegerThousands }} <small>km</small>
                </div>
                <div class="data-value"
                  [hidden]="!showMetricMeter(routeData.RouteInfo.Distance) || failedPlan || noRoute || loadingMap">
                  {{ routeData.RouteInfo.Distance | valueInteger }} <small>m</small>
                </div>
                <div class="data-value"
                  [hidden]="!showImperialMile(routeData.RouteInfo.Distance) || failedPlan || noRoute || loadingMap">
                  {{ routeData.RouteInfo.Distance*0.000621371192 | turnValueIntegerThousands }} <small>mi</small>
                </div>
                <div class="data-value"
                  [hidden]="!showImperialYard(routeData.RouteInfo.Distance) || failedPlan || noRoute || loadingMap">
                  {{ routeData.RouteInfo.Distance*1.0936133 | valueInteger }} <small>yard</small>
                </div>
                <div class="data-value" [hidden]="!failedPlan && !noRoute && !loadingMap">--</div>
              </div>
              <div class="data-element">
                <div class="data-name">
                  {{ languageService.languageJSON.InfoSidebar_TotalTime }}
                </div>
                <div class="data-value" [hidden]="failedPlan || noRoute || loadingMap">
                  {{ utilsService.timeConverter(routeData.RouteInfo.TotalTime) }}
                </div>
                <div class="data-value" [hidden]="!failedPlan && !noRoute && !loadingMap">--</div>
              </div>
            </div>
            <div class="flex-column">
              <div class="data-element">
                <div class="data-name">
                  {{ languageService.languageJSON.InfoSidebar_ChargingTime }}
                </div>
                <div class="data-value" [hidden]="failedPlan || noRoute || loadingMap">
                  {{ utilsService.timeConverter(routeData.RouteInfo.ChargeTime) }}
                </div>
                <div class="data-value" [hidden]="!failedPlan && !noRoute && !loadingMap">--</div>
              </div>
              <div class="data-element">
                <div class="data-name">
                  {{ languageService.languageJSON.InfoSidebar_ChargingStops }}
                </div>
                <div class="data-value" [hidden]="failedPlan || noRoute || loadingMap">
                  {{ routeData.RouteInfo.ChargingStops }}
                </div>
                <div class="data-value" [hidden]="!failedPlan && !noRoute && !loadingMap">--</div>
              </div>
            </div>
          </div>
          <div class="data-element">
            <div class="data-name">
              {{ languageService.languageJSON.Global_TotalConsumption }}
            </div>
            <div class="data-value" [hidden]="failedPlan || noRoute || loadingMap">
              {{ routeData.RouteInfo.TotalConsumption }} <small>kWh</small>
            </div>
            <div class="data-value" [hidden]="!failedPlan && !noRoute && !loadingMap">--</div>
          </div>
        </div>

        <!-- Route plan -->
        <div class="route-plan" [hidden]="loadingMap || noRoute">
          <div class="route-element-outer" *ngFor="let routePlanEl of routeData?.RoutePlan; let i = index"
            (click)="openRoutePlanPoint(routePlanEl, i)" [ngClass]="{'active': activeInfoSidebarRouteElement==i}">
            <div class="route-el-divider" *ngIf="i!=0">
              <div class="divider-outer">
                <div class="divider-el"></div>
              </div>
              <div class="distance-outer" *ngIf="settingsService.getUnit().Distance == 'metric'">{{
                routePlanEl.DistanceFromPreviousElement |
                turnValueIntegerThousands }} KM</div>
              <div class="distance-outer" *ngIf="settingsService.getUnit().Distance == 'imperial'">{{
                routePlanEl.DistanceFromPreviousElement*0.621371192 | turnValueIntegerThousands }} MI</div>
            </div>
            <div class="route-element">

              <div class="route-icon" *ngIf="routePlanEl.Type== RoutePlanType.Charger">
                <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/plug/' + routePlanEl.Icon"
                  alt="charging station" />
                <div class="charging-badge" [ngClass]="{'battery-reserved-warning': routePlanEl.BatteryReservedWarning}"
                  (click)="routePlanEl.BatteryReservedWarning ? openBatteryReservedSnackbar(): ''">{{
                  routePlanEl.ChargerIndex + 1 }}</div>
              </div>
              <div class="route-icon route-icon-small" *ngIf="routePlanEl.Type== RoutePlanType.StartPoint">
                <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/start_icon_list.svg'"
                  alt="start icon" />
              </div>
              <div class="route-icon route-icon-small" *ngIf="routePlanEl.Type==RoutePlanType.EndPoint">
                <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/target_icon_blue.svg'"
                  alt="end icon" />
                <div *ngIf="routePlanEl.BatteryReservedWarning" class="battery-reserved-warning-icon"
                  (click)="openBatteryReservedSnackbar()">!</div>
              </div>
              <div class="route-icon route-icon-small" *ngIf="routePlanEl.Type==RoutePlanType.WayPoint">
                <div class="waypoint-icon">{{ routePlanEl.WaypointIndex }}</div>
                <div *ngIf="routePlanEl.BatteryReservedWarning" class="battery-reserved-warning-icon"
                  (click)="openBatteryReservedSnackbar()">!</div>
              </div>

              <div class="flex-column">
                <div class="flex-el">
                  <div class="flex-el-left route-element-name"
                    [ngClass]="{'full-name': routePlanEl.Type==RoutePlanType.Charger}"> {{
                    routePlanEl.Name }} / <b>{{ routePlanEl.OperatorName }}</b></div>
                  <div class="flex-el-right" *ngIf="routePlanEl.Type!==RoutePlanType.Charger">
                    <img class="weather-icon" *ngIf="routePlanEl.Type!=RoutePlanType.StartPoint && routePlanEl.WeatherImg"
                      [src]="'/assets/icons/' + settingsService.getMap().Skin + '/weather/' + routePlanEl.WeatherImg"
                      alt="battery">
                    <img class="battery-icon"
                      [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/chargingplan_time_battery.svg'"
                      alt="battery">{{ $any(routePlanEl).BatteryLevel }}%
                  </div>
                </div>
                <div class="flex-el" *ngIf="routePlanEl.Type==RoutePlanType.Charger">
                  <div class="flex-el-left"></div>
                  <div class="flex-el-right">
                    <img class="clock-icon"
                      [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/chargingplan_time_icon.svg'"
                      alt="charge time">
                    {{ getChargeTime(routePlanEl.ChargeTimeHour,
                    routePlanEl.ChargeTimeMin) }}
                    <img class="weather-icon" *ngIf="routePlanEl.WeatherImg"
                      [src]="'/assets/icons/' + settingsService.getMap().Skin + '/weather/' + routePlanEl.WeatherImg"
                      alt="battery">
                    <img class="battery-icon"
                      [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/chargingplan_time_battery.svg'"
                      alt="battery">
                    {{ routePlanEl.BatteryMinPercent }}→{{
                    routePlanEl.BatteryMaxPercent }}%
                    <div class="fastcharge-info" *ngIf="isFastchargeIcon(routePlanEl.BatteryMaxPercent)"
                      (click)="openFastchargeDialog($event)">i</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Itinerary -->
        <div class="itinerary-dropdown" (click)="itineraryToggle()" [ngClass]="{'active': itineraryDropdown}"
          [hidden]="loadingMap || noRoute">
          <div class="itinerary-text">
            {{ languageService.languageJSON.InfoSidebar_Tooltip_Itinerary }}
          </div>
          <img class="itinerary-icon" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/open_arrow.svg'" />
        </div>
        <div class="itinerary" *ngIf="itineraryDropdown" [hidden]="loadingMap || noRoute">
          <ng-container *ngFor="let turn of routeData.Turns; let i = index">
            <div class="turn-element-outer">
              <div class="turn-element" (click)="navigateToTurn(turn)"
                [ngClass]="{ 'active': i == itineraryNextTurnIdx }">
                <div class="turn-leftside">
                  <div class="turn-leftside-element">
                    <div class="turn-icon" [ngClass]="{ 'combined-turn': turn.CombinedTurn }">
                      <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/turn/'  + turn.Icon[0]"
                        alt="turn" />
                    </div>
                    <div class="turn-instruction">
                      <div>{{ turn.Instruction[0] }}</div>
                    </div>
                  </div>
                  <div class="turn-leftside-element" *ngIf="turn.CombinedTurn">
                    <div class="turn-icon" [ngClass]="{ 'combined-turn': turn.CombinedTurn }">
                      <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/turn/' + turn.Icon[1]"
                        alt="turn" />
                    </div>
                    <div class="turn-instruction">
                      <div>{{ turn.Instruction[1] }}</div>
                    </div>
                  </div>
                </div>
                <div class="distance" [hidden]="!showMetricMeter(turn.DistanceFromStart)"> {{ turn.DistanceFromStart |
                  valueInteger }} M
                </div>
                <div class="distance" [hidden]="!showMetricKMeter(turn.DistanceFromStart)">
                  {{ turn.DistanceFromStart/1000 | turnValueIntegerThousands }} KM
                </div>
                <div class="distance" [hidden]="!showImperialYard(turn.DistanceFromStart)">
                  {{ turn.DistanceFromStart*1.0936133 | valueInteger }}
                  YARD
                </div>
                <div class="distance" [hidden]="!showImperialMile(turn.DistanceFromStart)">
                  {{ turn.DistanceFromStart*0.000621371192 | turnValueIntegerThousands }} MI
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>

    <div class="failed-plan-desktop-message" *ngIf="failedPlan && !navigation && selectedMode=='route'">
      <h4>{{ languageService.languageJSON.InfoSidebar_Routing_Failed }}</h4>
      <img class="failed-plan-img" src="assets/icons/default/error/planning_failed_image.png">
    </div>

  </div>

  <div class="navigation-menu-outer" *ngIf="selectedMode=='route' && !navigation"
    [hidden]="mobileVisiblePanel=='search'">
    <app-navigation-menu [failedPlan]="failedPlan" [noRoute]="noRoute" [loadingMap]="loadingMap!=null">
    </app-navigation-menu>
  </div>

  <div class="pane-drag-outer" #paneDragOuter [hidden]="mobileVisiblePanel=='search'">
    <div class="pane-drag-element"></div>
  </div>
</div>