import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CarSelectorDialogComponent } from '../../../modals/car-selector-dialog/car-selector-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { ConfirmationDialogComponent } from '../../../modals/confirmation-dialog/confirmation-dialog.component';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { skip } from 'rxjs/operators';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { Subscription } from 'rxjs';
import { ECar } from 'src/app/models/ecar';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-manage-cars',
  templateUrl: './manage-cars.component.html',
  styleUrls: ['./manage-cars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageCarsComponent implements OnInit {

  @ViewChild('vehicleScrollbar', { static: true }) vehicleScrollbar: NgScrollbar;
  @Input() expandedView: boolean;
  public vehicleListLoader: boolean = true;
  public vehicleList: ECar[] = [];
  public selectedCarNameString: string = "";
  public mobileVisiblePanel: string = "";
  private DeleteCarConfirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  private subscriptions: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef,
    private matDialog: MatDialog, public accountService: AccountService,
    public mobileResolutionService: MobileResolutionService, private _ngZone: NgZone,
    private stackedModalsService: StackedModalsService, public settingsService: SettingsService,
    public languageService: LanguageService, private consoleLoggerService: ConsoleLoggerService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.ObservableUserVehicles.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.vehicleList = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableOpenCarSelectorDialog.subscribe((resp) => {
      if (resp) {
        this.stackedModalsService.openModal(CarSelectorDialogComponent,
          { data: { firstUserCar: true }, autoFocus: false, panelClass: 'dialog-snap-to-fullscreen' });
      }
    }));

    this.subscriptions.push(this.accountService.ObservableVehicleLoader.subscribe((vehicleLoader: boolean) => {
      this.vehicleListLoader = vehicleLoader;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.settingsService.ObservableMap.pipe(skip(1)).subscribe((mapsettings) => {
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        this.cdr.detectChanges();
      }
    }));
  }

  public selectCar(vehicle: ECar): void {
    if (!vehicle.selected) {
      this.accountService.selectVehicle(vehicle.userVehicleID).subscribe();
    }
  }

  // return custom name or car name
  public getSelectedCarName(vehicle: ECar): string {
    return vehicle.name.length > 0 ? vehicle.name : vehicle.ECarType.Name;
  }

  public addCar(): void {
    this.stackedModalsService.openModal(CarSelectorDialogComponent, { autoFocus: false, panelClass: 'dialog-snap-to-fullscreen' });
  }

  public editCar(vehicleId: number): void {
    this.stackedModalsService.openModal(CarSelectorDialogComponent,
      { data: { editedUserCarId: vehicleId }, panelClass: 'dialog-snap-to-fullscreen', autoFocus: false });
  }

  public removeCar(vehicle: ECar): void {
    this._ngZone.run(() => {
      let data: { title, message, warning, warning2?, buttons } = {
        title: this.languageService.languageJSON.ManageCars_DeleteCar,
        message: this.languageService.languageJSON.ManageCars_DeleteCar_Description.replace("$carname", `<b>${this.getSelectedCarName(vehicle)}?</b>`),
        warning: this.languageService.languageJSON.History_DeleteCarWarning,
        buttons: [this.languageService.languageJSON.Global_Delete, this.languageService.languageJSON.Global_Cancel]
      }

      this.DeleteCarConfirmationDialogRef = this.matDialog.open(ConfirmationDialogComponent,
        {
          data: data, autoFocus: false
        });

      this.DeleteCarConfirmationDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.accountService.ObservableVehicleLoader.next(true);
          this.accountService.removeVehicle(vehicle.userVehicleID).subscribe(() => {
          },
            (error) => {
              if (error && error.name === "TimeoutError") {
                this.accountService.ObservableVehicleLoader.next(false);
                this._ngZone.run(() => {
                  this.matDialog.open(ConfirmationDialogComponent,
                    {
                      data: {
                        title: this.languageService.languageJSON.Global_Error, message: this.languageService.languageJSON.Global_SomethingWentWrong,
                        buttons: [this.languageService.languageJSON.Global_Ok, null]
                      }, autoFocus: false
                    });
                });
              }
            });
        }
        this.DeleteCarConfirmationDialogRef = null;
      });
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
