import { ECar } from "./ecar";
import { ECarType } from "./ecar-type";
import { TripPoint } from "./trip";
import { UserProfile } from "./user-profile";

export class User {
  Profile: UserProfile;
  Ecars: ECar[] = [];
  HistoryList = [];
  TipList: TripPoint[] = [];

  public getSelectedCar(): ECarType {
    return this.getSelectedECar()?.ECarType;
  }

  public getSelectedUserCar(): ECar {
    return this.getSelectedECar();
  }

  public getCarById(userVehicleID: number): ECar {
    return this.Ecars.find((el => { return el.userVehicleID == userVehicleID }));
  }

  public getCarNameById(userVehicleID: number): string {
    const userVehicle = this.getCarById(userVehicleID);
    if (userVehicle == undefined) {
      return null;
    }
    else if (userVehicle.name != "") {
      return userVehicle.name;
    }
    else {
      return userVehicle.ECarType.Name;
    }
  }

  private getSelectedECar(): ECar {
    return this.Ecars.find((el => { return el.selected }));
  }

  constructor() {
  }
}