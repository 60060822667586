import { Component, OnInit, Input, NgZone } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationDisclaimerComponent } from '../../modals/navigation-disclaimer/navigation-disclaimer.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { GpsErrorDialogComponent } from '../../modals/gps-error-dialog/gps-error-dialog.component';
import { MapService } from 'src/app/services/map.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import device from 'current-device';
import { LocationService } from 'src/app/services/location.service';
import { WebviewService } from 'src/app/services/webview.service';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { Position } from 'src/app/models/position';
import { LatLng } from 'leaflet';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {
  @Input() noRoute: boolean;
  @Input() failedPlan: boolean;
  @Input() loadingMap: boolean;
  public MobileApp: boolean = false;

  constructor(public matDialog: MatDialog, private navigationService: NavigationService,
    private inputParamsService: InputParamsService,
    private mapService: MapService, private locationService: LocationService, public webviewService: WebviewService,
    private _ngZone: NgZone, public languageService: LanguageService,
    public settingsService: SettingsService, public accountService: AccountService,
    private consoleLoggerService: ConsoleLoggerService) { }

  ngOnInit() {
    this.MobileApp = this.webviewService.IsGPSTWebview();
  }

  public startNavigation(): void {
    if (!this.failedPlan && !this.noRoute && !this.loadingMap) {
      if (!this.webviewService.IsGPSTWebview() && !window.navigator.userAgent.includes("FB_IAB")) {
        // navigation disclaimer
        this.locationService.getCurrentPosition().subscribe(
          (location) => {
            if (document.getElementsByClassName("mat-dialog-title").length == 0) {
              this._ngZone.run(() => {
                let dialogRef = this.matDialog.open(NavigationDisclaimerComponent, { data: {}, autoFocus: false });
                dialogRef.afterClosed().subscribe((resp) => {
                  if (resp == "start") {
                    this.navigationService.ObservableChangeNavigation.next("start");
                  }
                });
              });
            }
          },
          (error) => {
            this.openGpsErrorDialog();
          });
      }
      else {
        if (device.android()) {
          this.webviewService.setBatteryOptimizationSettingsRequired(false);
          this.consoleLoggerService.log("getBatteryOptimizationActive", this.webviewService.getBatteryOptimizationActive());
          if (this.webviewService.getBatteryOptimizationActive()) {
            this.webviewService.setBatteryOptimizationSettingsRequired(true);
          }
          this.webviewService.StartNavigation();

          const lastPosition: Position = this.locationService.getLastPosition();
          if (lastPosition) {
            if (document.getElementsByClassName("mat-dialog-title").length == 0) {
              this._ngZone.run(() => {
                let dialogRef = this.matDialog.open(NavigationDisclaimerComponent, { data: {}, autoFocus: false })
                dialogRef.afterClosed().subscribe((resp) => {
                  if (resp == "start") {
                    this.navigationService.ObservableChangeNavigation.next("start");
                  }
                });
              });
            }
          }
        }
        else {
          this.webviewService.StartNavigation();

          const lastPosition: Position = this.locationService.getLastPosition();
          if (lastPosition) {
            if (document.getElementsByClassName("mat-dialog-title").length == 0) {
              this._ngZone.run(() => {
                let dialogRef = this.matDialog.open(NavigationDisclaimerComponent, { data: {}, autoFocus: false })
                dialogRef.afterClosed().subscribe((resp) => {
                  if (resp == "start") {
                    this.navigationService.ObservableChangeNavigation.next("start");
                  }
                });
              });
            }
          }
        }
      }
    }
  }

  // open error dialog modal
  private openGpsErrorDialog(): void {
    if (!this.webviewService.IsGPSTWebview()) {
      this._ngZone.run(() => {
        let dialogRef = this.matDialog.open(GpsErrorDialogComponent);
      });
    }
  }

  private setStartCoordsToYourGeolocation(): void {
    this.inputParamsService.setStartCoordsToYourGeolocation().subscribe((resp) => {
      if (resp == "nogps") {
        this.openGpsErrorDialog();
      }
      else {
        const startcoord = this.inputParamsService.getStartCoordsParams();
        this.mapService.getReverseGeocode(startcoord.lat, startcoord.lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: startcoord.lat,
              lng: startcoord.lng,
              wp: 0
            });
          }
        }, () => { });
      }
    });
  }

  public backToRha(): void {
    this._ngZone.run(() => {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent,
        {
          data: {
            title: this.languageService.languageJSON.NavigationMenu_ExitPlanning, message: this.languageService.languageJSON.NavigationMenu_ExitPlanning_Message,
            buttons: [this.languageService.languageJSON.Global_Exit, this.languageService.languageJSON.Global_Cancel]
          }, autoFocus: false
        });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.inputParamsService.setSelectedMode("rha");
          const startcoord = this.inputParamsService.getWaypointsParams()[0];
          this.inputParamsService.setWaypointParams([startcoord]);
          this.inputParamsService.setECarSettings(this.inputParamsService.getECarSettings());
          this.mapService.ObservableMoveTo.next({ latlng: new LatLng(startcoord.lat, startcoord.lng), zoom: 12 });
        }
        else {

        }
      });
    });
  }
}
