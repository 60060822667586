import { LatLng } from "leaflet";
import { WindElement } from "./wind";
import { Waypoint } from "./range-params";

export interface Route {
    RoutePlan: RoutePlanElement[],
    RoutePoints: RoutePoint[],
    RouteInfo: RouteInfo,
    RouteEVSpeedLimitSegments: RouteEVSpeedLimitSegment[],
    Temperature: number,
    Turns: Turn[],
    WaypointsAddresses: WaypointAddress[],
    WeatherCode: number,
    Wind: WindElement[],
    Incidents: Incident[],
    RoadSurfaceAlerts: RoadSurfaceAlert[],
}

export interface Incident {
    Location: LatLng,
    Type: string,
    Severity: string
}

export interface RoadSurfaceAlert {
    Location: LatLng,
    Type: string,
    Severity: number
}

export enum RoutePlanType {
    StartPoint,
    EndPoint,
    WayPoint,
    Charger
}

export interface RoutePlanElement {
    Type: RoutePlanType,
    Name: string,
    Icon: string,
    Location: LatLng,
    DistanceFromStart: number,
    DistanceFromPreviousElement: number,
    BatteryReservedWarning: boolean,
    BatteryLevel: number,
    WaypointIndex: number,
    BatteryMinPercent: number,
    BatteryMaxPercent: number,
    ChargeTimeHour: number,
    ChargeTimeMin: number,
    OperatorName: string,
    OperatorId: string,
    ChargingStationId: string,
    ChargerIndex: number,
    ChargePower: number,
    WeatherImg: string
}

export interface ChargePlan {
    BatteryLevelFrom: number,
    BatteryLevelTo: number,
    ChargeTimeMin: number,
    Distance: number,
}

export interface RoutePoint {
    Location: LatLng,
    Soc: number,
    RouteSpeedLimit: number,
    EVSpeedLimit: number,
    Altitude: number,
    JamFactor: number,
    DistanceFromPreviousPoint: number,
    TimeFromPreviousPoint: number
}

export interface RouteInfo {
    ChargeTime: number,
    Distance: number,
    ChargingStops: number,
    TotalTime: number,
    TotalConsumption: number
}

export interface RouteEVSpeedLimitSegment {
    SpeedLimit: number,
    StartRouteIndex: number,
    EndRouteIndex: number,
    ShowInfoAtRouteIndex: number
}

export interface Turn {
    Idx: number,
    TypeIndex: number,
    Id: string,
    Instruction: string[],
    Icon: string[],
    CombinedTurn: boolean,
    RoutePointIdxStart: number,
    RoundaboutExitNumber: number,
    DistanceFromStart: number,
    RoutePointIdxEnd: number,
    IsLeftHandTraffic: boolean,
    LaneInfo: Lane[]
}

export interface NextTurn {
    NextTurnInstruction: Turn,
    NextTurnDistance: number,
    SpeedLimit: number,
    DistToDest: number,
    TimeToDest: number,
    EstimatedBatteryLevel: number,
    DistToNextWaypoint: number,
    TimeToNextWaypoint: number,
    NextWaypointDisplayName: string,
    DistToNextChargingStation: number,
    TimeToNextChargingStation: number,
    NextWaypointBatteryReservedWarning: boolean,
    NextChargingStationBatteryReservedWarning: boolean,
    DestBatteryReservedWarning: boolean
}

export interface Lane {
    Highlighted: boolean,
    Images: string[]
}

export interface WaypointAddress {
    City: string,
    Country: string,
    County: string,
    District: string,
    Housenumber: string,
    Street: string,
    Location: Waypoint
}
