<div class="subscription-dialog" [ngClass]="{ 'pro': type?.includes('pro.'), 'proplus': type?.includes('proplus.') }">

    <h1 mat-dialog-title>
        Congratulations!
    </h1>
    <div mat-dialog-content>
        <div class="logo">
            <img src="assets/icons/dark/logo/ev_navigation_logo.png"/>
            <h2>PRO</h2>
        </div>
        <img *ngIf="type?.includes('proplus.')" id="proplus-icon" src="assets/icons/default/ui/pro_logo.svg"/>
        <div class="content">
            <p>Welcome to the PRO<small *ngIf="type?.includes('proplus.')">+</small> package!</p>
        </div>
        
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="close()">{{
            languageService.languageJSON.Global_Ok }}</button>
    </div>

</div>