<mat-card class="example-card unselectable" id="range-input-elements-right" [ngClass]="{ 'mobile-mode-selector' :  mobileResolution && mobileVisiblePanel!='range',
  'hide': mobileResolution && (mobileVisiblePanel=='wind' || mobileVisiblePanel=='search'),
  'route-mode': selectedMode == 'route', 'multiline': selectedCarMultilineString != '', 'logged-in': manageCars,
  'manage-cars': (mobileVisiblePanel=='manageCars' && mobileResolution), 'manage-cars-expanded': manageCarsExpanded && !mobileResolution,
  'navigation': navigation, 'manage-cars-expanded-mobile': manageCarsExpanded && mobileResolution}">

  <div class="mobile-opener-icon-outer">
    <div class="mobile-opener-icon" (click)="openCarSelectorDialog()">
      <div class="triangle-icon"></div>
    </div>
  </div>
  <div class="vehicle-tile-name" (click)="openCarSelectorDialog()">
    <div class="vehicle-tile-img">
      <img id="vehicle-image" alt="electric vehicle" [src]="mapService.getSelectedCarIcon()"
        [ngClass]="{'multiline': selectedCarMultilineString != '' }" />
    </div>
    <div class="vehicle-tile-text">
      <h2> {{ selectedCarNameString }} </h2>
      <h2 class="vehicle-tile-text-type"> {{ selectedCarMultilineString }} </h2>
    </div>
    <img class="opener-icon" [src]="'/assets/icons/' + settingsService.getMap().Skin +'/ui/open_arrow.svg'" />
  </div>
  <app-manage-cars #manageCarsComponent [expandedView]="manageCarsExpanded" [hidden]="!manageCars"></app-manage-cars>
    <ng-scrollbar #sliderScrollbar class="sliderScrollbar" [autoHeightDisabled]="false" [autoWidthDisabled]="true" pointerEventsMethod="scrollbar">
      <div class="scroll-active-width">
        <div class="sliders-el">
          <div class="range-input-element" #flatRangeSlider>
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_BatteryLevel}}</span>
              <span class="slider-value"> {{ (batteryLevel) }} % </span>
            </p>
            <app-rha-slider #batterySlider [imageSource]="flatRangeImg" [min]="batteryLevelMin"
              [max]="100" [digit]="0" [step]="90"
              [altString]="'battery level'" [(value)]="batteryLevel"
              (valueChangeEvent)="sliderParamsChanged(); batterySliderChanged();">
            </app-rha-slider>
          </div>
          <div class="range-input-element" #weightSlider>
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_Weight}}</span>
              <span class="slider-value"> {{ weight }} {{ languageService.languageJSON.SliderSidebar_Person}} </span>
            </p>
            <app-rha-slider [imageSource]="weightImg" [min]="weightMin" [max]="weightMax" [digit]="0" [step]="5"
              [(value)]="weight" [altString]="'weight'"
              (valueChangeEvent)="sliderParamsChanged(); weightSliderChanged();"></app-rha-slider>
          </div>
          <div class="range-input-element" #tirePressureSlider *ngIf="!navigation">
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_TirePressure}}</span>
              <span class="slider-value"> {{ tirePressureString }} </span>
            </p>
            <app-rha-slider [imageSource]="tirePressureImg" [min]="1.5" [max]="2.6" [digit]="1" [step]="5"
              [altString]="'tire pressure'" [(value)]="tirePressure"
              (valueChangeEvent)="sliderParamsChanged(); getTirePressure();">
            </app-rha-slider>
          </div>
          <div class="range-input-element" #userBehaviorSlider *ngIf="!navigation">
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_UserBehavior}}</span>
              <span class="slider-value"> {{ userBehaviorString }} </span>
            </p>
            <app-rha-slider [imageSource]="userBehaviorImg" [min]="0" [max]="100" [digit]="0" [step]="100"
              [altString]="'user behavior'" [(value)]="userBehavior"
              (valueChangeEvent)="sliderParamsChanged(); getUserBehavior();"></app-rha-slider>
          </div>
          <div class="range-input-element" #speedLimiterSlider *ngIf="!navigation && selectedMode=='rha'">
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_SpeedLimiter}}</span>
              <span class="slider-value"> {{ speedLimiterString }} </span>
            </p>
            <app-rha-slider [imageSource]="speedLimiterImg" [min]="speedLimiterMin" [max]="speedLimiterMax" [digit]="0"
              [step]="50" [altString]="'speed limiter'" [(value)]="speedLimiter"
              (valueChangeEvent)="sliderParamsChanged(); getSpeedLimiter();"></app-rha-slider>
          </div>
          <div class="range-input-element" #minBatteryAtDestinationSlider *ngIf="!navigation && selectedMode=='route'">
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_MinBatAtDest}}</span>
              <span class="slider-value"> {{ minBatteryAtDestination }} %</span>
            </p>
            <app-rha-slider [imageSource]="minBatteryAtDestinationImg" [min]="10" [max]="75" [digit]="0" [step]="65"
              [altString]="'min battery at desination'" [(value)]="minBatteryAtDestination"
              (valueChangeEvent)="sliderParamsChanged();"></app-rha-slider>
          </div>
          <div class="range-input-element" #batterySafetyLimitSliderRha *ngIf="!navigation && selectedMode=='rha'">
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_BatteryReserved}}</span>
              <span class="slider-value"> {{ batterySafetyRhaString }} </span>
            </p>
            <app-rha-slider [imageSource]="batterySafetyLimitImg" [min]="20" [max]="50" [altString]="'battery at arrival'"
              [digit]="0" [step]="30" [(value)]="batterySafetyLimitRha"
              (valueChangeEvent)="sliderParamsChanged(); getBatterySafetyLimit();"></app-rha-slider>
          </div>

          <div class="range-input-element" #batterySafetyLimitSliderRoute *ngIf="!navigation && selectedMode=='route'">
            <p>
              <span class="slider-name">{{ languageService.languageJSON.SliderSidebar_BatteryReserved}}</span>
              <span class="slider-value"> {{ batterySafetyLimitRoute }} %</span>
            </p>
            <app-rha-slider [imageSource]="batterySafetyLimitImg" [min]="10" [max]="30" [altString]="'battery at arrival'"
              [digit]="0" [step]="30" [(value)]="batterySafetyLimitRoute" (valueChangeEvent)="sliderParamsChanged();">
            </app-rha-slider>
          </div>
        </div>
      </div>
    </ng-scrollbar>
</mat-card>
<div *ngIf="mobileResolution && (mobileVisiblePanel=='range')" class="close-button" (click)="mobileResolutionService.setMobileVisiblePanel('')">
  X
</div>