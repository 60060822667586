import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-subscription-delete-dialog',
  templateUrl: './subscription-delete-dialog.component.html',
  styleUrl: './subscription-delete-dialog.component.scss'
})
export class SubscriptionDeleteDialogComponent {

  public type: SubscriptionType;

  constructor(public dialogRef: MatDialogRef<SubscriptionDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, private accountService: AccountService) {

    this.type = this.data.subscriptionType.SubscriptionType;
    console.log(this.type);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public delete(): void{
    this.accountService.setUserSubscriptionType(SubscriptionType.Free);
    this.dialogRef.close();
  }
}
